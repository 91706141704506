import styled from "styled-components";

export const BaseP = styled.p`
  font-size: 16px;
  color: ${({ theme }) => theme.textColors.grey};
  line-height: 24px;
`;

export const OpenSansP = styled.h1`
  font-family: OpenSans;
`;

export const LustriaH6 = styled.h6`
  font-size: 1.2rem;
  font-family: "Lustria";
  color: ${({ theme }) => theme.textColors.grey};
`;

export const LustriaH5 = styled.h5`
  font-size: 1.3rem;
  font-family: "Lustria";
  color: ${({ theme }) => theme.textColors.grey};
`;

export const LustriaH4 = styled.h4`
  font-size: 1.5rem;
  font-family: "Lustria";
  color: ${({ theme }) => theme.textColors.grey};
`;

export const LustriaH3 = styled.h3`
  font-size: 1.7rem;
  font-family: "Lustria";
  color: ${({ theme }) => theme.textColors.grey};
`;

export const LustriaH2 = styled.h2`
  font-size: 2rem;
  font-family: "Lustria";
  color: ${({ theme }) => theme.textColors.grey};
`;

export const LustriaH1 = styled.h1`
  font-size: 39px;
  font-family: "Lustria";
  color: ${({ theme }) => theme.textColors.grey};
`;
