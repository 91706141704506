import React, { useState } from "react";
import { useLocation } from "@reach/router";
import styled from "styled-components";
import FormFieldLabelled from "./FormFieldLabelledComponent";
import SubmitButtonComponent from "./SubmitButtonComponent";
import axios from "axios";
import {
  GoogleReCaptcha,
  GoogleReCaptchaProvider,
} from "react-google-recaptcha-v3";
import { OpenSansP, LustriaP } from "../../text/";

export const FormDesktop = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 24px;
  padding: 64px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media only screen and (max-width: 800px) {
    border-radius: 24px;
    padding: 32px 24px;
    align-items: flex-start;
    padding: 32px 24px;
  }
`;
export const Title = styled(LustriaP)`
  width: 384px;
  font-size: ${(props) =>
    props.theme.fonts.desktop2HeadingXlBlackDefaultLeft.size};
  line-height: ${(props) =>
    props.theme.fonts.desktop2HeadingXlBlackDefaultLeft.lineHeight};
  margin-bottom: 16px;

  @media only screen and (max-width: 800px) {
    max-width: 318px;
  }
`;
export const Paragraph = styled(OpenSansP)`
  max-width: 384px;
  font-size: ${(props) =>
    props.theme.fonts.desktopTextLargeBlackDefaultLeft.size};
  line-height: ${(props) =>
    props.theme.fonts.desktopTextLargeBlackDefaultLeft.lineHeight};
  color: ${({ theme }) => theme.textColors.greySecondary};
  margin-bottom: 24px;

  @media only screen and (max-width: 800px) {
    max-width: 278px;
  }
`;
export const FormFieldLabelledItem = styled(FormFieldLabelled)`
  &:not(:last-of-type) {
    margin-bottom: 24px;
  }
`;

export const CheckboxText = styled.p`
  max-width: 347px;
  font-family: ${(props) => props.theme.fonts.buttonLeftWhite.family};
  font-size: ${(props) => props.theme.fonts.buttonLeftWhite.size};
  font-weight: ${(props) => props.theme.fonts.buttonLeftWhite.weight};
  line-height: ${(props) => props.theme.fonts.buttonLeftWhite.lineHeight};
  color: ${(props) => props.theme.textColors.gray};
  text-decoration: underline;
`;
export const SuccessMessage = styled.span`
  font-family: ${(props) => props.theme.fonts.buttonLeftWhite.family};
  font-size: 16px;
  line-height: ${(props) => props.theme.fonts.buttonLeftWhite.lineHeight};
  color: ${(props) => props.theme.textColors.steelBlue};
  margin-top: 20px;
`;

export const FailureMessage = styled(SuccessMessage)`
  color: ${(props) => props.theme.textColors.red};
`;

export const SubmitBtn = styled(SubmitButtonComponent)`
  transition: all 0.3s;
  background-color: ${(props) => props.theme.colors.steelBlue};
  border-radius: 4px;
  padding: 11px 25px;
  display: flex;
  align-items: flex-start;
  font-family: Raleway-Semibold;
  font-size: 18px;
  line-height: ${(props) => props.theme.fonts.buttonLeftWhite.lineHeight};
  color: ${(props) => props.theme.textColors.white};
  text-align: center;
  margin-top: 20px;
  margin-bottom: 10px;
  cursor: pointer;
  border: ${({ theme }) => "1px solid " + theme.colors.steelBlue};
  :hover {
    background-color: ${({ theme }) => theme.colors.grey};
    border: ${({ theme }) => "1px solid " + theme.colors.grey};
  }
  :focus {
    border: ${({ theme }) => "1px solid "};

    background-color: ${({ theme }) => theme.colors.blueGreen};
  }
  :active {
    background-color: ${({ theme }) => theme.colors.darkBlue};
  }
  outline: none;
`;
const FormDesktopComponent = ({ className }) => {
  const RECAPTCHA_SITE_KEY = "6LfAgfgeAAAAAC5qe_ZiKNJPIzQZOzVqRzyWJ_hK";
  const FORM_POST_URL = "/post-contact-form";
  const submitUrl = useLocation().origin + "/#formPanel";
  let token = "";
  const [submitState, setSubmitState] = useState({
    submitting: false,
    success: null,
    statusMessage: null,
  });

  const submitHandler = (e) => {
    e.preventDefault();
    const form = e.target;
    setSubmitState({ submitting: true });
    const data = new FormData(form);
    data.append("g-recaptcha-response", token);

    axios({ method: "post", url: FORM_POST_URL, data })
      .then((r) => {
        handleServerResponse(
          true,
          "Thank you. Your request has been received!",
          form
        );
      })
      .catch((error) => {
        handleServerResponse(
          false,
          "There was an error submitting your request. Please try again later!",
          form
        );
      });
  };

  const handleServerResponse = (success, msg, form) => {
    setSubmitState({
      submitting: false,
      success: success,
      statusMessage: msg,
    });
    setTimeout(() => setSubmitState({ statusMessage: null }), 5 * 1000);
    if (success) {
      form.reset();
    }
  };

  return (
    <div id="formPanel">
      <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITE_KEY}>
        <form
          name="contact"
          onSubmit={submitHandler}
          role="form"
          aria-label="Contact form"
        >
          <input type="hidden" name="form-name" value="skildare-contact" />
          <FormDesktop className={className}>
            <Title>Ready to talk?</Title>
            <Paragraph>
              Fill out the below form and one of our friendly HR experts will
              contact you.
            </Paragraph>
            <FormFieldLabelledItem
              placeholder="John Smith"
              label="Full name"
              name="ctl_udf_dc5c4afa-83b1-4dbb-b11f-b546d26f837b"
              pattern=".*[^ ].*"
              required={true}
            />
            <FormFieldLabelledItem
              placeholder="john.smith@gmail.com"
              label="Email address"
              name="ctl_email"
              type="email"
              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
              required={true}
            />
            <FormFieldLabelledItem
              placeholder="(02) 1234 5678"
              label="Phone number"
              name="ctl_udf_221abb7a-a78b-4f68-98dc-9877afc7d4d8"
              required={true}
            />
            <FormFieldLabelledItem
              placeholder="John Smith LTD"
              label="Business name"
              name="ctl_udf_7c14942a-4300-4f26-aca1-2527dd2b13e2"
            />
            <FormFieldLabelledItem
              type="select"
              label="Preferred contact time"
              name="ctl_udf_665e120e-8ebd-47b1-8e47-226503a1d32a"
              required={true}
            >
              <option value="Morning">Morning</option>
              <option value="Afternoon">Afternoon</option>
              <option value="After hours">After hours</option>
              <option value="Weekend">Weekend</option>
              <option value="Anytime">Anytime</option>
            </FormFieldLabelledItem>

            <FormFieldLabelledItem
              type="textarea"
              label="Comments"
              name="ctl_udf_1c9df991-ad20-416f-affc-0d748f425c04"
            />

            <FormFieldLabelledItem
              type="select"
              placeholder="Monday afternoon"
              label="I would like to stay up to date with all the latest in HR by subscribing to Skildare’s free information HR Newsletter"
              name="ctl_udf_6ca16cc3-2c14-4d3f-a344-b3e07250accc"
              required={true}
            >
              <option value="Yes please">Yes please</option>
              <option value="No thank-you">No, thank you</option>
            </FormFieldLabelledItem>

            <input type="hidden" name="action" value="validate_captcha" />
            <input
              type="hidden"
              id="hdn_cs_submiturl"
              name="hdn_cs_submiturl"
              value={submitUrl}
            />
            <input
              type="hidden"
              id="hdn_cs_notify"
              name="hdn_cs_notify"
              value="true"
            />
            <input type="hidden" name="hdn_cs_templateid" value="undefined" />
            <input
              type="hidden"
              name="hdn_cs_id"
              value="777dc45c-1a9c-436c-a546-564322699c6b"
            />
            <SubmitBtn disabled={submitState.submitting} />
            {submitState.success ? (
              <SuccessMessage>{submitState.statusMessage}</SuccessMessage>
            ) : (
              <FailureMessage>{submitState.statusMessage}</FailureMessage>
            )}

            <GoogleReCaptcha
              onVerify={(t) => {
                token = t;
              }}
            />
          </FormDesktop>
        </form>
      </GoogleReCaptchaProvider>
    </div>
  );
};
export default FormDesktopComponent;
