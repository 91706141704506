import styled from "styled-components";

export const BaseP = styled.p`
  font-size: 16px;
  color: ${({ theme }) => theme.textColors.grey};
  line-height: 24px;
`;

export const OpenSansP = styled(BaseP)`
  font-family: OpenSans;
`;

export const LustriaP = styled(BaseP)`
  font-family: Lustria;
`;
