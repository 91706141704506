import React from "react";
import { Helmet } from "react-helmet";
import { RichText, Link } from "prismic-reactjs";

const isBrowser = typeof window !== "undefined";
const clearImageUrl = (url) => {
  // here is the example of an image url stored in the Prismic CMS https://images.prismic.io/skildare-website/d15c1744-0898-47fa-9298-b34b28e2ddc5_home_hero.jpeg?auto=compress,format
  // We would like to drop this part => ?auto=compress,format.

  const clearedImageUrl = url.split("?")[0];
  return clearedImageUrl || url;
};

const mapBusinessInformationToLdJson = (businessInformation) => ({
  "@context": "http://www.schema.org",
  "@type": "EmploymentAgency",
  name: RichText.asText(businessInformation.business_name),
  url: RichText.asText(businessInformation.url),
  logo: RichText.asText(businessInformation.logo),
  description: RichText.asText(businessInformation.description),
  address: {
    "@type": "PostalAddress",
    streetAddress: RichText.asText(businessInformation.address),
    addressLocality: RichText.asText(businessInformation.city),
    addressRegion: RichText.asText(businessInformation.state_region),
    postalCode: RichText.asText(businessInformation.zip),
    addressCountry: RichText.asText(businessInformation.country),
  },
  telephone: RichText.asText(businessInformation.telephone),
});

const SEO = ({
  seo,
  siteUrl = isBrowser ? window.location.origin : "",
  publishDate,
  author,
}) => {
  if (!seo) return null;
  const firstSeoElementFromPrismic = seo[0];

  if (!firstSeoElementFromPrismic) return null;
  return (
    <Helmet>
      <title>{RichText.asText(firstSeoElementFromPrismic.seo_title)}</title>
      {isBrowser && (
        <link
          rel="canonical"
          href={`${siteUrl}${window.location.pathname}`}
        ></link>
      )}
      <meta
        name="description"
        content={RichText.asText(firstSeoElementFromPrismic.seo_description)}
      />
      <meta
        property="og:title"
        content={RichText.asText(firstSeoElementFromPrismic.seo_title)}
      />
      <meta
        property="og:description"
        content={RichText.asText(firstSeoElementFromPrismic.seo_description)}
      />
      {author && <meta name="author" content={author}></meta>}
      {publishDate && (
        <meta
          name="publish_date"
          property="og:publish_date"
          content={publishDate}
        ></meta>
      )}
      <meta
        name="image"
        property="og:image"
        content={clearImageUrl(Link.url(firstSeoElementFromPrismic.seo_image))}
      />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="627" />
      <meta property="og:image:type" content="image/jpeg" />
      <meta
        property="twitter:title"
        content={RichText.asText(firstSeoElementFromPrismic.seo_title)}
      />
      <meta
        property="twitter:description"
        content={RichText.asText(firstSeoElementFromPrismic.seo_description)}
      />
      <meta
        property="twitter:image"
        content={clearImageUrl(Link.url(firstSeoElementFromPrismic.seo_image))}
      />
      <meta name="twitter:card" content={"summary"} />
      <meta property="og:url" content={isBrowser ? window.location.href : ""} />
      <meta
        name="keywords"
        content={RichText.asText(firstSeoElementFromPrismic.keywords)}
      />
      {firstSeoElementFromPrismic.business_information && (
        <script type="application/ld+json">
          {JSON.stringify(
            mapBusinessInformationToLdJson(
              firstSeoElementFromPrismic.business_information
            )
          )}
        </script>
      )}
      <script
        async
        defer
        src="https://static.cdn.prismic.io/prismic.js?new=true&repo=skildare-website"
      ></script>
    </Helmet>
  );
};

export default SEO;
