import React from "react";
import styled, { css } from "styled-components";

const formField = css`
  width: ${(props) => props.inline ? "auto" : "327px;"}; 
  border-radius: 4px;
  padding: 11px 15px;
  border: 1px solid ${(props) => props.theme.colors.formFieldBorder};
  font-family: ${(props) => props.theme.fonts.formFieldInput.family};
  font-size: ${(props) => props.theme.fonts.formFieldInput.size};
  font-weight: ${(props) => props.theme.fonts.formFieldInput.weight};
  line-height: ${(props) => props.theme.fonts.formFieldInput.lineHeight};
  color: ${({ theme }) => theme.colors.grey};
  ::placeholder {
    width: 327px;
    font-family: ${(props) => props.theme.fonts.formFieldPlaceholder.family};
    font-size: ${(props) => props.theme.fonts.formFieldPlaceholder.size};
    font-weight: ${(props) => props.theme.fonts.formFieldPlaceholder.weight};
    line-height: ${(props) =>
      props.theme.fonts.formFieldPlaceholder.lineHeight};
    color: ${({ theme }) => theme.colors.mainBlue};
    opacity: 0.6;
  }
`;

export const FormFieldLabelled = styled.div`
  padding: 1px 0px 0px;
  display: flex;
  flex-direction: ${(props) => props.inline ? "row" : "column"};
  align-items: ${(props) => props.inline ? "center" : "flex-start"};
  gap: ${(props) => props.inline ? "10px" : "0"};

  &.hidden-item {
    margin-bottom: 0;

    &:not(:last-of-type) {
      margin-bottom: 0;
    }
  }  
`;
export const Label = styled.label`
  font-family: OpenSans;
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
  max-width: 327px;
  color: ${({ theme }) => theme.colors.grey};
  margin-bottom: 3px;
`;
export const Input = styled.input`
  ${formField};
`;

const TextArea = styled.textarea`
  ${formField}
  height: 120px;
`;

const Select = styled.select`
  ${formField}
`;
export const RequiredMark = styled.strong`
  color: red;
`;

const FormFieldLabelledComponent = ({
  className,
  children,
  value,
  onChange,
  name,
  label,
  placeholder,
  pattern,
  required,
  type,
  inline
}) => {
  return (
    <FormFieldLabelled className={className} inline={inline}>
      <Label htmlFor={name}>
        {label}
        {required && <RequiredMark>*</RequiredMark>}
      </Label>
      {type === "textarea" ? (
        <TextArea id={name} name={name} />
      ) : type === "select" ? (
        <Select
          id={name}
          name={name}
          value={value}
          onChange={onChange}
          required={required}
        >
          {children}
        </Select>
      ) : (
        <Input
          type={type ? type : "text"}
          placeholder={placeholder}
          id={name}
          name={name}
          value={value}
          onChange={onChange}
          pattern={pattern}
          required={required}
          inline={inline}
        />
      )}
    </FormFieldLabelled>
  );
};

export default FormFieldLabelledComponent;
